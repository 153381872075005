/*
    function to make lead square api call
*/
// accessKey={{AccessKey}}&secretKey={{SecretKey}}

// async function makeLSApiCall(path: string, method: string, body: any) {
//   try {
//     const res = await fetch(
//       `${LEAD_SQUARE_URL}/${path}?accessKey=${AccessKey}&secretKey=${SecretKey}`,
//       {
//         method,
//         body: JSON.stringify(body),
//       }
//     );

//     if (res.ok) {
//       const data = await res.json();

//       return {
//         data,
//         error: null,
//       };
//     } else {
//       return {
//         data: null,
//         error: "Something Went Wrong",
//       };
//     }
//   } catch (e: any) {
//     return {
//       data: null,
//       error: e.message,
//     };
//   }
// }

const makeLSApiCall = async (payload) => {
  try {
    const response = await fetch("/api/leadsquared.com/lead-create", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => response.json());

    return {
      isDuplicate: !!response?.isDuplicate,
      data: response,
      error: null,
    };
  } catch (e) {
    return { error: true };
  }
};

export async function captureLead(body: {
  fullName: string;
  phoneNumber: string;
  emailId: string;
  countryCode: string;
  program: string;
  degree: string;
}) {
  // const data = {
  //   FirstName: body?.fullName,
  //   Phone: body?.phoneNumber,
  //   EmailAddress: body?.emailId,
  //   mx_Country: body?.countryCode,
  //   mx_Program: body?.program,
  // };
  const [fname, lname] = String(body.fullName).split(" ");
  const payload = [
    {
      Attribute: "EmailAddress",
      Value: body.emailId,
    },
    {
      Attribute: "FirstName",
      Value: fname,
    },
    {
      Attribute: "LastName",
      Value: lname || fname,
    },
    {
      Attribute: "Phone",
      Value: body.phoneNumber,
    },
    {
      Attribute: "mx_Program",
      Value: body.program,
    },
    {
      Attribute: "mx_Program_Vertical",
      Value: body.degree,
    },
  ];

  const res = await makeLSApiCall(payload);

  return res;
}
