import Markdown from "@/components/atoms/Markdown";
import styles from "./FeesCard.module.scss";
import cn from "clsx";

export default function FeesCard({
  FeesInInr,
  Image,
  Heading = null,
  SecondaryHeading,
  FeesInUSD,
  country,
  ShowHeadingOnTop = false,
}: any) {
  return (
    <div
      className={`rounded-2xl flex items-center gap-2 justify-between text-primary-blue lining-nums ${styles.root}`}
      key={SecondaryHeading}
    >
      <div
        className={cn(
          "text-raleway h-full flex flex-col justify-between",
          styles.FeesCard__Container__V2,
        )}
      >
        <div>
          {!!Heading && ShowHeadingOnTop ? (
            <p className={styles.heading}>{Heading}</p>
          ) : null}
          <p className={cn(`font-medium`, styles.FeesSection__Heading)}>
            {SecondaryHeading}
          </p>
        </div>
        <div>
          <Markdown as="h2" className="text-[42px] font-extrabold lg:text-[48px] lg:leading-[70px] lining-nums">
            {country == "IN" ? FeesInInr : FeesInUSD}
          </Markdown>
        </div>
      </div>
      <div className={cn("h-full", styles.imgContainer)}>
        <img
          src={Image?.data?.attributes?.url}
          className=""
          alt={Image?.data?.attributes?.alternativeText || ''}
        />
      </div>
    </div>
  );
}
