import Markdown from "@/components/atoms/Markdown";
import FeesCard from "@molecules/FeesCard/FeesCardV2";

const EmiModalContent = ({ Fees }) => {
  const full = Fees?.FeesOptions?.find((o) => o.Type == "Full");
  const emis = Fees?.FeesOptions?.filter((o) => o.Type == "Emi");

  return (
    <div>
      <div className="flex items-end">
        {Fees?.Title?.enabled ? (
          <Markdown as="h2" className="flex-none text-blu font-baskervville text-4xl leading-4xl capitalize">
            {Fees?.Title?.text || "Emi Options"}
          </Markdown>
        ) : null}
        <div className="flex-auto h-[2px] bg-[#D4D4D4] mb-2 ml-2"></div>
      </div>
      <div className="grid gap-4 lg:grid-cols-3 lg:gap-x-4 my-7">
        {full ? (
          <FeesCard
            {...full}
            country="IN"
            Image={{
              data: { attributes: { url: "/_a/icons/rupee-2.png" } },
            }}
            ShowHeadingOnTop={Fees?.ShowHeadingOnTop}
          />
        ) : null}
        {emis.map((item: any, i: number) => {
          return (
            <FeesCard
              {...item}
              key={i}
              country={item?.Country || "IN"}
              Image={
                item?.Image || {
                  data: { attributes: { url: "/_a/icons/rupee.png" } },
                }
              }
              ShowHeadingOnTop={Fees?.ShowHeadingOnTop}
            />
          );
        })}
      </div>
      <p className="text-[0.9rem] text-blu font-medium">
        {Fees?.Subtitle ||
          "* No Cost EMI offers may vary in terms and conditions, including the eligibility criteria, minimum purchase amounts, and maximum tenures. The calculations on this website are general estimates and do not account for the specific terms of individual offers. Also, 4% Interest from 3rd year onwards for 36 months EMI Plan for all UG Programs will be applicable."}
      </p>
    </div>
  );
};

export default EmiModalContent;

// .filter((item) => {
//   return (country != "IN" && item.FeesInUSD) || country == "IN";
// }).
