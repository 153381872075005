enum ProgramType {
  MBA = "mba",
}

export enum GAEvent {
  NAVBAR_ENROLL_NOW = "NavBar_enrol_now",
  HERO_CARD_ENROLL_NOW = "HeroCard_enrol_now",
  HERO_CARD_SUBMIT_DETAILS = "HeroCard_submit_details",
  HERO_CARD_SUBMIT_OTP = "Herocard_Submit_OTP",
  HERO_CARD_PROCEED = "Herocard_Proceed",
  HERO_CARD_CANCEL = "Herocard_cancel",
  HERO_CARD_LEAD_VERIFIED = "HeroCard_Lead_verified",
  PROGRAM_CARD_MBA = "Program_card_mba",
  PROGRAM_CARD_DOWNLOAD_BROCHURE = "Program_card_Download_Brochure",
  PROGRAM_CARD_SUBMIT_BASIC_DETAILS = "Program_card_Submit_Basic_Details",
  PROGRAM_CARD_SUBMIT_OTP = "Program_card_submit_otp",
  PROGRAM_CARD_DOWNLOAD_BROCHURE_URL = "Program_card_Download_Brochure_url",
  PROGRAM_CARD_PROCEED = "Program_card_proceed",
  PROGRAM_CARD_CANCEL = "Program_card_cancel",
  PROGRAM_CARD_LEAD_VERIFIED = "ProgramCard_Lead_verified",
  PROGRAM_TAB_MBA = "Program_tab_mba",
  PROGRAM_TAB_DOWNLOAD_BROCHURE = "Program_tab_Download_Brochure",
  PROGRAM_TAB_SUBMIT_BASIC_DETAILS = "Program_tab_Submit_Basic_Details",
  PROGRAM_TAB_SUBMIT_OTP = "Program_tab_submit_otp",
  PROGRAM_TAB_DOWNLOAD_BROCHURE_URL = "Program_tab_Download_Brochure_url",
  PROGRAM_TAB_PROCEED = "Program_tab_proceed",
  PROGRAM_TAB_CANCEL = "Program_tab_cancel",
  PROGRAM_TAB_LEAD_VERIFIED = "Program_tab_lead_verified",
  PROGRAM_MBA = "Program_mba",
  PROGRAM_DOWNLOAD_BROCHURE = "Program_Download_Brochure",
  PROGRAM_SUBMIT_BASIC_DETAILS = "Program_Submit_Basic_Details",
  PROGRAM_SUBMIT_OTP = "Program_submit_otp",
  PROGRAM_DOWNLOAD_BROCHURE_URL = "Program_Download_Brochure_url",
  PROGRAM_PROCEED = "Program_proceed",
  PROGRAM_CANCEL = "Program_cancel",
  PROGRAM_LEAD_VERIFIED = "Program_lead_verified",
  STICKY_CTA_TEL = "sticky_cta_tel",
  STICKY_CTA_WHATSAPP = "sticky_cta_tel_whatsapp",
  FOOTER_ENROLL_NOW = "footer_enroll_now",
  CALL_US_NAVBAR = "call_us_navbar",
  CALL_US_MODAL_CLICK = "call_us_modal_click",
  NAVIGATION_CLICK = "navigation_click",
  CALL_CHAT_INTERACTION = "call_chat_interaction",
  LEAD_INITIATE ="lead_initiate",
  LEAD_SUBMIT = "lead_submit",
}

enum ComponentName {
  NAVBAR = "Navbar",
  HERO_CARD = "HeroCard",
  PROGRAM_TAB = "ProgramTab",
  PROGRAM_CARD = "ProgramCard",
  PROGRAM_DETAILS = "ProgramDetails",
  SIDEBAR_STICKYS = "SidebarSticky",
  BANNER_ENROLL_NOW = "BannerEnrollNow",
  MODAL = "Modal"
}

export interface IProgramPageSessionData {
  isNavbarClick : boolean;
  isMbaPage : boolean
}

export interface IGAEvent {
  businessEntity?: string;
  city?: string;
  component_name?: string;
  country?: string;
  course_category?: string;
  device_type?: string;
  email_id?: string;
  enhanced_conversion?: any;
  enhanced_conversion_data?: any;
  event: GAEvent;
  label?: string;
  lead_identifier?: string;
  logged_in_flag?: boolean;
  lpUrl?: string;
  offering_key_indicator?: string;
  page_category?: string;
  page_title?: string;
  page_url?: string;
  platform_os?: string;
  platform_version?: string;
  session_id?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_keyword?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
  uuid?: string;
  paid_user?: boolean;
  cta_text?: string;
  category?: string;
  sub_category?: string;
  header_title?: string;
  section_name?: string;
  redirection_url?: string;
  page_type?: string;
}

export enum PageCategory {
  NAVIGATION = "Home Navigation",
  HERO_CARD = "Hero Card",
  PROGRAM_TAB = "Program Tab",
  PROGRAM_CARD = "Program Card",
  PROGRAM_DETAILS = "Program Details",
  HOME = "Home Page",
}

export const programsEnabledForGAEvents = new Set<ProgramType>([
  ProgramType.MBA,
]);

export const ga_events_mapping: { [key: string]: ProgramType } = {
  "/master-of-business-administration-online": ProgramType.MBA,
};

export const GA_EVENTS: Map<GAEvent, IGAEvent> = new Map([
  [
    GAEvent.NAVBAR_ENROLL_NOW,
    {
      event: GAEvent.NAVBAR_ENROLL_NOW,
      label: "Nav Enroll Now Button Clicked",
      page_category: PageCategory.NAVIGATION,
      component_name: ComponentName.NAVBAR,
      course_category: "",
    },
  ],
  [
    GAEvent.HERO_CARD_ENROLL_NOW,
    {
      event: GAEvent.HERO_CARD_ENROLL_NOW,
      label: "Enroll Now Button Clicked",
      page_category: PageCategory.HERO_CARD,
      component_name: ComponentName.HERO_CARD,
      course_category: "",
    },
  ],
  [
    GAEvent.HERO_CARD_SUBMIT_DETAILS,
    {
      event: GAEvent.HERO_CARD_SUBMIT_DETAILS,
      label: "Signup Submit Details Button Clicked",
      page_category: PageCategory.HERO_CARD,
      component_name: ComponentName.HERO_CARD,
      course_category: "",
    },
  ],
  [
    GAEvent.HERO_CARD_SUBMIT_OTP,
    {
      event: GAEvent.HERO_CARD_SUBMIT_OTP,
      label: "Submit OTP Button Clicked",
      page_category: PageCategory.HERO_CARD,
      component_name: ComponentName.HERO_CARD,
      course_category: "",
    },
  ],
  [
    GAEvent.HERO_CARD_PROCEED,
    {
      event: GAEvent.HERO_CARD_PROCEED,
      label: "Enroll Now Proceed Clicked",
      page_category: PageCategory.HERO_CARD,
      component_name: ComponentName.HERO_CARD,
      course_category: "",
    },
  ],
  [
    GAEvent.HERO_CARD_CANCEL,
    {
      event: GAEvent.HERO_CARD_CANCEL,
      label: "Enroll Now Cancel Clicked",
      page_category: PageCategory.HERO_CARD,
      component_name: ComponentName.HERO_CARD,
      course_category: "",
    },
  ],
  [
    GAEvent.PROGRAM_TAB_MBA,
    {
      event: GAEvent.PROGRAM_TAB_MBA,
      label: "MBA Button Clicked",
      page_category: PageCategory.PROGRAM_TAB,
      component_name: ComponentName.PROGRAM_TAB,
      course_category: "Master of business administration",
    },
  ],
  [
    GAEvent.PROGRAM_TAB_DOWNLOAD_BROCHURE,
    {
      event: GAEvent.PROGRAM_TAB_DOWNLOAD_BROCHURE,
      label: "Download Brochure Button Clicked",
      page_category: PageCategory.PROGRAM_TAB,
      component_name: ComponentName.PROGRAM_TAB,
      course_category: "Master of business administration",
    },
  ],
  [
    GAEvent.PROGRAM_TAB_SUBMIT_BASIC_DETAILS,
    {
      event: GAEvent.PROGRAM_TAB_SUBMIT_BASIC_DETAILS,
      label: "Submit Basic Details Button Clicked",
      page_category: PageCategory.PROGRAM_TAB,
      component_name: ComponentName.PROGRAM_TAB,
      course_category: "Master of business administration",
    },
  ],
  [
    GAEvent.PROGRAM_TAB_SUBMIT_OTP,
    {
      event: GAEvent.PROGRAM_TAB_SUBMIT_OTP,
      label: "Submit OTP Button Clicked",
      page_category: PageCategory.PROGRAM_TAB,
      component_name: ComponentName.PROGRAM_TAB,
      course_category: "Master of business administration",
    },
  ],
  [
    GAEvent.PROGRAM_TAB_DOWNLOAD_BROCHURE_URL,
    {
      event: GAEvent.PROGRAM_TAB_DOWNLOAD_BROCHURE_URL,
      label: "Download Brochure Button Clicked",
      page_category: PageCategory.PROGRAM_TAB,
      component_name: ComponentName.PROGRAM_TAB,
      course_category: "Master of business administration",
    },
  ],
  [
    GAEvent.PROGRAM_TAB_CANCEL,
    {
      event: GAEvent.PROGRAM_TAB_CANCEL,
      label: "Cancel Button Clicked",
      page_category: PageCategory.PROGRAM_TAB,
      component_name: ComponentName.PROGRAM_TAB,
      course_category: "Master of business administration",
    },
  ],
  [
    GAEvent.PROGRAM_TAB_PROCEED,
    {
      event: GAEvent.PROGRAM_TAB_PROCEED,
      label: "Proceed Button Clicked",
      page_category: PageCategory.PROGRAM_TAB,
      component_name: ComponentName.PROGRAM_TAB,
      course_category: "Master of business administration",
    },
  ],
  [
    GAEvent.PROGRAM_MBA,
    {
      event: GAEvent.PROGRAM_MBA,
      label: "MBA Button Clicked",
      page_category: PageCategory.PROGRAM_DETAILS,
      component_name: ComponentName.PROGRAM_DETAILS,
      course_category: "Master of business administration",
    },
  ],
  [
    GAEvent.PROGRAM_DOWNLOAD_BROCHURE,
    {
      event: GAEvent.PROGRAM_DOWNLOAD_BROCHURE,
      label: "Download Brochure Button Clicked",
      page_category: PageCategory.PROGRAM_DETAILS,
      component_name: ComponentName.PROGRAM_DETAILS,
      course_category: "Master of business administration",
    },
  ],
  [
    GAEvent.PROGRAM_SUBMIT_BASIC_DETAILS,
    {
      event: GAEvent.PROGRAM_SUBMIT_BASIC_DETAILS,
      label: "Submit Basic Details Button Clicked",
      page_category: PageCategory.PROGRAM_DETAILS,
      component_name: ComponentName.PROGRAM_DETAILS,
      course_category: "Master of business administration",
    },
  ],
  [
    GAEvent.PROGRAM_SUBMIT_OTP,
    {
      event: GAEvent.PROGRAM_SUBMIT_OTP,
      label: "Submit OTP Button Clicked",
      page_category: PageCategory.PROGRAM_DETAILS,
      component_name: ComponentName.PROGRAM_DETAILS,
      course_category: "Master of business administration",
    },
  ],
  [
    GAEvent.PROGRAM_DOWNLOAD_BROCHURE_URL,
    {
      event: GAEvent.PROGRAM_DOWNLOAD_BROCHURE_URL,
      label: "Download Brochure Button Clicked",
      page_category: PageCategory.PROGRAM_DETAILS,
      component_name: ComponentName.PROGRAM_DETAILS,
      course_category: "Master of business administration",
    },
  ],
  [
    GAEvent.PROGRAM_CANCEL,
    {
      event: GAEvent.PROGRAM_CANCEL,
      label: "Cancel Button Clicked",
      page_category: PageCategory.PROGRAM_DETAILS,
      component_name: ComponentName.PROGRAM_DETAILS,
      course_category: "Master of business administration",
    },
  ],
  [
    GAEvent.PROGRAM_PROCEED,
    {
      event: GAEvent.PROGRAM_PROCEED,
      label: "Proceed Button Clicked",
      page_category: PageCategory.PROGRAM_DETAILS,
      component_name: ComponentName.PROGRAM_DETAILS,
      course_category: "Master of business administration",
    },
  ],
  [
    GAEvent.PROGRAM_LEAD_VERIFIED,
    {
      event: GAEvent.PROGRAM_LEAD_VERIFIED,
      label: "Lead Found MBA brochure",
      page_category: PageCategory.PROGRAM_DETAILS,
      component_name: ComponentName.PROGRAM_DETAILS,
      course_category: "Master of business administration",
    },
  ],
  [
    GAEvent.PROGRAM_CARD_MBA,
    {
      event: GAEvent.PROGRAM_CARD_MBA,
      label: "MBA Card Clicked",
      page_category: PageCategory.PROGRAM_CARD,
      component_name: ComponentName.PROGRAM_CARD,
      course_category: "Master of business administration",
    },
  ],
  [
    GAEvent.PROGRAM_CARD_DOWNLOAD_BROCHURE,
    {
      event: GAEvent.PROGRAM_CARD_DOWNLOAD_BROCHURE,
      label: "Program Card master of business administration-online",
      page_category: PageCategory.PROGRAM_CARD,
      component_name: ComponentName.PROGRAM_CARD,
      course_category: "Master of business administration",
    },
  ],
  [
    GAEvent.PROGRAM_CARD_SUBMIT_BASIC_DETAILS,
    {
      event: GAEvent.PROGRAM_CARD_SUBMIT_BASIC_DETAILS,
      label: "Submit Form Download brochure",
      page_category: PageCategory.PROGRAM_CARD,
      component_name: ComponentName.PROGRAM_CARD,
      course_category: "Master of business administration",
    },
  ],
  [
    GAEvent.PROGRAM_CARD_SUBMIT_OTP,
    {
      event: GAEvent.PROGRAM_CARD_SUBMIT_OTP,
      label: "Submit OTP, Download brochure",
      page_category: PageCategory.PROGRAM_CARD,
      component_name: ComponentName.PROGRAM_CARD,
      course_category: "Master of business administration",
    },
  ],
  [
    GAEvent.PROGRAM_CARD_DOWNLOAD_BROCHURE_URL,
    {
      event: GAEvent.PROGRAM_CARD_DOWNLOAD_BROCHURE_URL,
      label: "Download brochure",
      page_category: PageCategory.PROGRAM_CARD,
      component_name: ComponentName.PROGRAM_CARD,
      course_category: "Master of business administration",
    },
  ],
  [
    GAEvent.PROGRAM_CARD_CANCEL,
    {
      event: GAEvent.PROGRAM_CARD_CANCEL,
      label: "Cancel Card Download brochure",
      page_category: PageCategory.PROGRAM_CARD,
      component_name: ComponentName.PROGRAM_CARD,
      course_category: "Master of business administration",
    },
  ],
  [
    GAEvent.PROGRAM_CARD_PROCEED,
    {
      event: GAEvent.PROGRAM_CARD_PROCEED,
      label: "Proceed MBA brochure",
      page_category: PageCategory.PROGRAM_CARD,
      component_name: ComponentName.PROGRAM_CARD,
      course_category: "Master of business administration",
    },
  ],
  [
    GAEvent.PROGRAM_CARD_LEAD_VERIFIED,
    {
      event: GAEvent.PROGRAM_CARD_LEAD_VERIFIED,
      label: "Lead Found MBA brochure",
      page_category: PageCategory.PROGRAM_CARD,
      component_name: ComponentName.PROGRAM_CARD,
      course_category: "Master of business administration",
    },
  ],
  [
    GAEvent.PROGRAM_TAB_LEAD_VERIFIED,
    {
      event: GAEvent.PROGRAM_TAB_LEAD_VERIFIED,
      label: "Lead Found MBA brochure",
      page_category: PageCategory.PROGRAM_TAB,
      component_name: ComponentName.PROGRAM_TAB,
      course_category: "Master of business administration",
    },
  ],
  [
    GAEvent.HERO_CARD_LEAD_VERIFIED,
    {
      event: GAEvent.HERO_CARD_LEAD_VERIFIED,
      label: "Lead Found Hero card",
      page_category: PageCategory.HERO_CARD,
      component_name: ComponentName.HERO_CARD,
      course_category: "",
    },
  ],
  [
    GAEvent.STICKY_CTA_TEL,
    {
      event: GAEvent.STICKY_CTA_TEL,
      label: "sticky cta tel click",
      page_category: PageCategory.HOME,
      component_name: ComponentName.SIDEBAR_STICKYS,
      course_category: "",
    },
  ],
  [
    GAEvent.STICKY_CTA_WHATSAPP,
    {
      event: GAEvent.STICKY_CTA_WHATSAPP,
      label: "sticky cta whatsapp click",
      page_category: PageCategory.HOME,
      component_name: ComponentName.SIDEBAR_STICKYS,
      course_category: "",
    },
  ],
  [
    GAEvent.FOOTER_ENROLL_NOW,
    {
      event: GAEvent.FOOTER_ENROLL_NOW,
      label: "sticky cta whatsapp click",
      page_category: PageCategory.HOME,
      component_name: ComponentName.BANNER_ENROLL_NOW,
      course_category: "",
    },
  ],
  [
    GAEvent.CALL_US_NAVBAR,
    {
      event: GAEvent.CALL_US_NAVBAR,
      label: "Call Us Nav bar click",
      page_category: PageCategory.HOME,
      component_name: ComponentName.NAVBAR,
      course_category: "",
    },
  ],
  [
    GAEvent.CALL_US_MODAL_CLICK,
    {
      event: GAEvent.CALL_US_MODAL_CLICK,
      label: "Call Us Modal click",
      page_category: PageCategory.HOME,
      component_name: ComponentName.MODAL,
      course_category: "",
    },
  ],
  [
    GAEvent.NAVIGATION_CLICK,
    {
      event:GAEvent.NAVIGATION_CLICK,
      cta_text: "",
      category: "",
      sub_category: "",
      header_title: "",
      section_name: "",
      redirection_url: "",
      page_type: ""
    }
  ],
  [
    GAEvent.CALL_CHAT_INTERACTION,
    {
      event: GAEvent.CALL_CHAT_INTERACTION,
      cta_text: "",
      section_name: "",
      page_type: ""
    }
  ],
  [
    GAEvent.LEAD_INITIATE,
    {
      event:GAEvent.LEAD_INITIATE,
      cta_text: "",
      section_name: "",
      header_title: "",
      page_type: ""
    }
  ],
  [
    GAEvent.LEAD_SUBMIT,
    {
      event:GAEvent.LEAD_SUBMIT,
      cta_text: "NA",
      section_name: "NA",
      header_title: "NA",
      page_type: "NA",
      lead_starting_cta:"NA",
      lead_starting_url:"NA",
      lead_id:"NA",
      status: false,
      error_message:"NA"
    }
  ]
]);

// set default values on each events 
export const GA_EVENTS_PAYLOAD = new Map(
  Array.from(GA_EVENTS).map(([key, value]) => [
    key, 
    {
      ...value, 
      business_entity: 'Amity Online',
      logged_in_flag:false,
      offering_key_indicator : "NA",
      email_id : "NA",
      lp_url : "NA",
      enhanced_conversion : {},
      enhanced_conversion_data : {},
      session_id : "NA",
      uuid : "NA",
      country : "NA",
      city : "NA",
      paid_user : false,
    }
  ])
);
