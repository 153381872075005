import { remark } from "remark";
import html from "remark-html";

export default function Markdown({
  children,
  as: _as = "div",
  sanitize = true,
  ...rest
}: {
  children: string;
  as?: string;
  [key: string]: any;
}) {
  let __html = remark()
    .use(html, { sanitize })
    .processSync(children)
    .toString();
    __html = __html.replace(/<\/?p[^>]*>/g, '');
  let As: any = _as || "div";
  return (
    <As
      dangerouslySetInnerHTML={{
        __html,
      }}
      
      {...rest}
    />
   
  );
}
